import { Locales } from "../../../localization/types";
import {externalLinks} from "../../../pages-data/_V2/common/links";
import Presenation from "../ProductPresentation/assets/passkit-presentation-cards.png";

export const getPasskitMeta = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			title: 'Электронные карты лояльности для ресторанов и кафе | Quick Resto Казахстан',
			metaTags: [
				{
					name: 'description',
					content:
						'Электронные карты лояльности в Apple Wallet и Google Pay ✔ Инструменты автоматизации маркетинга ✔ Сбор базы гостей ✔ Единая CRM-система для обслуживания гостей в зале и на доставку → Внедрение под ключ. Автоматизируй бизнес в Казахстане',
				},
				{
					property: 'og:url',
					content: `${externalLinks.quickrestoKZ.href}passkit/`,
				},
				{
					property: 'og:title',
					content: 'Электронные карты лояльности для ресторанов и кафе | Quick Resto Казахстан',
				},
				{
					property: 'og:description',
					content:
						'Электронные карты лояльности в Apple Wallet и Google Pay ✔ Инструменты автоматизации маркетинга ✔ Сбор базы гостей ✔ Единая CRM-система для обслуживания гостей в зале и на доставку → Внедрение под ключ. Автоматизируй бизнес в Казахстане',
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:image',
					content: `${externalLinks.quickrestoKZ.href}${Presenation}`,
				},
			],
			canonical: `${externalLinks.quickrestoKZ.href}passkit/`,
		}
	}

	if (locale === 'ru-BY') {
		return {
			title: 'Электронные карты лояльности для ресторанов и кафе | Quick Resto Беларусь',
			metaTags: [
				{
					name: 'description',
					content:
						'Электронные карты лояльности в Apple Wallet и Google Pay ✔ Инструменты автоматизации маркетинга ✔ Сбор базы гостей ✔ Единая CRM-система для обслуживания гостей в зале и на доставку → Внедрение под ключ. Автоматизируй бизнес в Беларуси',
				},
				{
					property: 'og:url',
					content: `${externalLinks.quickrestoBY.href}passkit/`,
				},
				{
					property: 'og:title',
					content: 'Электронные карты лояльности для ресторанов и кафе | Quick Resto Беларусь',
				},
				{
					property: 'og:description',
					content:
						'Электронные карты лояльности в Apple Wallet и Google Pay ✔ Инструменты автоматизации маркетинга ✔ Сбор базы гостей ✔ Единая CRM-система для обслуживания гостей в зале и на доставку → Внедрение под ключ. Автоматизируй бизнес в Беларуси',
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:image',
					content: `${externalLinks.quickrestoBY.href}${Presenation}`,
				},
			],
			canonical: `${externalLinks.quickrestoBY.href}passkit/`,
		}
	}

	return {
		title: 'Электронные карты лояльности клиентов ресторанов и кафе — заказать создание | Quick Resto',
		metaTags: [
			{
				name: 'description',
				content:
					'Электронные карты лояльности в Apple Wallet и Google Pay  ✔ Инструменты автоматизации маркетинга ✔ Сбор базы гостей ✔ Единая CRM-система для обслуживания гостей в зале и на доставку → Внедрение под ключ',
			},
			{
				property: 'og:url',
				content: `${externalLinks.quickrestoRU.href}passkit/`,
			},
			{
				property: 'og:title',
				content: 'Электронные карты лояльности клиентов ресторанов и кафе — заказать создание | Quick Resto',
			},
			{
				property: 'og:description',
				content:
					'Электронные карты лояльности в Apple Wallet и Google Pay  ✔ Инструменты автоматизации маркетинга ✔ Сбор базы гостей ✔ Единая CRM-система для обслуживания гостей в зале и на доставку → Внедрение под ключ',
			},
			{
				property: 'og:type',
				content: 'website',
			},
			{
				property: 'og:image',
				content: `${externalLinks.quickrestoRU.href}${Presenation}`,
			},
		],
		canonical: `${externalLinks.quickrestoRU.href}passkit/`,
	}
}
